#report-builder-main-container{
    display: flex;
    min-height: 80vh;
    width: 100%;
}

#report-side-bar-container{
    width: 20%;
    height: 100%;
    background-color: #f3f7fd;
    padding: 10px;
}

.layout-items-item-no-type-container h2{
    padding: 5px;
}

#elements-side-bar-container input{
    width: 85%;
}

.element-name{
    font-size: 12px;
}

#report-builder-container{
    width: 100%;
    padding: 10px;
    background-color: #f3f7fd;
    margin-left: 30px;
}

#report-side-bar-menu-item-container{
    display: flex;
    justify-content: space-around;
}

#report-side-bar-menu-item-container h2{
    cursor: pointer;
}

.element-container{
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-bottom: 10px;
    background-color: white;
    margin: 5px;
    cursor: pointer;
    width: 30%;
}

.element-drag-icon{
    cursor: grab;
}

.report-layout-container {
    height: 80vh;
}

.report-item{
    padding: 10px;
    background-color: white;
    min-height: 50px;
    width: 98%;
  }

  #layout-elements-container{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
}

.layout-elements-element-container{
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    margin: 5px;
    border: 1px dotted lightgray;
    padding: 10px;
    display: flex;
    min-width: 50%;
}

.layout-elements-element-container:hover{
    border: 1px solid lightgray;
}

#layout-items-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.layout-items-item-container{
    margin-bottom: 10px;
    width: 100%;
    border: 1px dotted lightgray;
    display: flex;
}

.layout-items-item-container:hover{
    border: 1px solid lightgray;
}

#report-layout-options-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
}

#report-layout-options-container svg{
    cursor: pointer;
    fill: #e4e4e4;
    padding: 3px;
}

#report-layout-options-container svg:hover{
    fill: gray;
}

#element-no-content-container{
    padding: 10px;
    border: 1px dotted lightgray;
    border-radius: 15px;
    margin-bottom: 10px;
    background-color: white;
}

#elements-side-bar-paragraph-container textarea{
    width: 80%;
    height: 400px;
}

#live-reports-color-picker{
    width: 20px !important;
    padding: 0px
}

.layout-items-item-icon-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.live-report-side-bar-image-container img{
    height: 100px;
    width: 100px;
    margin: 10px;
    object-fit: contain;
}

.live-report-side-bar-image-container img:hover{
    cursor: pointer;
    border: 1px solid gray;
}

#live-report-media-item-container img{
    width: 100%;
}

#report-layout-item-container{
    display: flex;
    align-items: center;
    border: 1px dotted lightgray;
}

#report-layout-item-container:hover{
    border: 1px solid lightgray;
}

.sidebar-element-items-overview-container{
    display: flex;
    height: 30px;
    width: 200px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
}

.sidebar-element-items-overview-container p{
    margin: 0;
}

.sidebar-layout-elements-overview-container{
    display: flex;
}

.sidebar-layout-elements-overview-item-container{
    height: 50px;
    width: 50px;
    border: 1px solid lightgray;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.sidebar-report-element-container{
    height: 50px;
    width: 90%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
}

#live-report-title-item-container{
    width: 100%;
}

#layout-items-item-paragraph-container{
    padding: 10px;
}

/* #report-side-sticky-container{
    position: fixed;
    width: 10%;
} */

#sidebar-items-container{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.sidebar-delete-container img{
    height: 20px;
    cursor: pointer;
}

#live-report-qoute-item-icon-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

#live-report-qoute-name-item{
    text-align: center;
}

#live-report-media-item-container{
    width: 100%;
}

.live-report-side-bar-qoute-container p{
    cursor: pointer;
}

#live-report-activity-graph-container{
    width: 100%;
}
  