.add-step-container{
    display: flex;
    cursor: pointer;
    align-items: center;
}

.add-step-container img{
    width: 20px;
    cursor: pointer;
    margin-right: 10px;
}

.persona-step-container{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid lightgray;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    margin: 30px 0px 20px 0px;
}

.edit-type-container img{
    width: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.edit-type-container{
    display: flex;
    align-items: center;
}

.person-step-delete-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.persona-step-delete-icon{
    width: 25px;
    cursor: pointer;
}

.persona-inner-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.persona-detail-banner{
    width: 300px;
    border-radius: 50%;
}

.dashboard-step-container{
    padding: 20px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: #cbf6fd14;
}

.dashboard-step-description{
    margin-left: 20px;
}

.show-research{
    cursor: pointer;
}

.persona-image{
    height: 150px;
    border-radius: 50%;
}

.dashboard-persona-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    text-align: center;
    margin: 0px 30px;
}

.dashboard-persona-container img{
    height: 100px;
    border-radius: 50%;
}

.dashboard-persona-outer-container{
    display: flex;
    justify-content: flex-start;
}

#personas-table-photo{
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

#edit-persona-phor-container{
    display: flex;
}

#edit-persona-photo-icon{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

#edit-persona-name-input{
    border: none;
    box-shadow: none;
    font-size: 35px;
    text-align: center;
    background-color: transparent;
}

#persona-personal-data-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.persona-option-container{
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    background-color: white;
}

.persona-option-container h2{
    text-align: left;
    font-size: 25px;
}

.edit-persona-button-container{
    margin-top: 10px;
}

.persona-info-container{
   margin-top: 30px;
   width: 100%;
}

.select-activity-container-personas{
    justify-content: center;
    margin-bottom: 50px;
}

.dashboard-outflow-title-container select{
    border: none;
    font-weight: bold;
    margin-right: 5px;
}

#persona-report-item-container{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
    margin-bottom: 20px;
}

#persona-report-actions-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#presence-day-container{
    display: flex;
    align-items: center;
}

#presence-day-container img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

#presence-day-weekday{
    margin-right: 10px;
    width: 100px;
}

.presence-time-container{
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.presence-time-container input{
    width: 100px;
    box-shadow: none;
    border: none;
}

#persona-report-meta-bar-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#report-user-meta-container{
    display: flex;
    align-items: center;
}

#report-user-meta-container img{
    height: 30px;
    margin-right: 10px;
}
