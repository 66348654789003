.section-explainer-header-image-container{
    width: 100%;
    height: 200px;
    margin-top: 50px;
}

.section-explainer-header-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.checkup-container{
    width: 95%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #f4f4f4;
}

.checkup-container svg{
    font-size: 50px;
    margin-right: 20px;
}

.quality-check-cta-container{
    margin-top: 50px;
}

#add-results-explainer-options-container{
    display: flex;
    flex-direction: column;
}

.add-results-explainer-options-item-container{
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 20px;
    width: 95%;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
}

.section-explainer-text-container{
    min-height: 70vh;
    width: 100%;
}

.explainer-page-segments-container{
    display: flex;
    margin: 50px 0px;
    flex-wrap: wrap;
    width: 100%;
}

.explainer-page-segments-item-container{
    display: flex;
    flex-direction: column;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 20px;
    width: 20%;
    background-color: white;
    cursor: pointer;
    margin: 10px;
}

.explainer-page-segments-item-container h3{
    font-size: 15px;
}

.explainer-page-segments-item-container p{
    font-size: 12px;
}

.progress-bar {
    display: flex;
    justify-content: space-between;
    margin: 30px 0px 30px 0px;
    border-radius: 15px;
    background-color: #4caf50;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
.step {
    position: relative;
    cursor: pointer;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.info {
    background-color: white;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

#progress-bar-impactstrategy-info-modal-button-container{
    display: flex;
    justify-content: space-between;
    margin: 50px -10px -10px -10px;
    padding: 20px; 
    background-color: #f3f7fd;
}

#section-explainer-progressbar-container{
    margin-top: 50px;
}

#section-explainer-progressbar-title{
    text-align: left;
}

#segment-overview-steps-container{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.segment-overview-step-container{
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
}

.segment-overview-step-inner-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.segment-overview-step-inner-container svg{
    height: 25px;
    width: 25px;
    margin-right: 20px;
}

.segment-overview-checked-name-container{
    display: flex;
    align-items: center;
}

#segment-overview-step-index{
    font-weight: 300;
}

#segment-overview-step-button{
    cursor: pointer
}

#segment-overview-step-button:hover{
    text-decoration: underline;
}