.home-video-container{
    display: flex;
    width: 60%;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #f4f4f4;
    margin-bottom: 30px;
    padding: 20px;
    margin-right: 30px;
}

.home-video-container iframe{
    width: 100%;
    height: 100%;
}

.home-quick-start-container{
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
}

.impact-guide-container{
    width: 90%;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #f4f4f4;
    margin-bottom: 30px;
    display: flex;
}

.home-element-title-container{
    width: 100%;
    margin-left: 10%;
}

.home-element-title-container h2{
    text-align: left;
}

#home-strategy-cta-container{
    background-color: green;
}

.home-start-image-container{
    height: 150px;
    width: 150px;
    margin-right: 30px;
}

.home-start-image-container img{
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
}

.home-start-image-container svg{
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
}

.add-results-start-image-container{
    height: 100px;
    width: 150px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#impact-guide-container h1{
    text-align: left;
    margin: 20px 0px;
}

#home-get-started-items-container{
    margin-left: 30px;
}

.home-get-started-container-inline-link{
    color: #63cadc;
}

.home-get-started-explainer-button-container{
    margin-left: 30px;
}

.home-get-started-explainer-button-container p{
    margin-top: 0px;
}

.home-get-started-button-container svg{
    fill: #63cadc;
    cursor: pointer;
}

.home-cta-container{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: green;
    width: 20%;
}

.home-cta-container svg{
    fill: white;
    height: 50px;
    width: 50px;
}

.home-cta-container:hover{
    background-color: #e6f0fc;
}

.home-cta-container p{
    margin-left: 5px;
    color: white;
}

.home-section-explainer-container{
    width: 70%;
   
}

#add-results-start-container{
    margin-top: 50px;
}