.title-edit-container{
    display: flex;
    align-items: center;
}

.title-edit-container img{
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.home-section-title-container img{
    height: 25px;
    margin-right: 10px;
    cursor: default;
}

.home-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.kpi-home-container{
    justify-content: flex-start;
    width: 100%;
}

.source-container{
    margin-top: auto;
    margin-bottom: 0px;
}

.kpi-results-container{
    min-height: 90%;
    border-bottom: 1px solid lightgray;
}

.kpi-results-container h1{
    color: rgb(244, 129, 131)
}

.home-stats-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.home-stat-container{
    margin: 20px;
    width: 150px;
    cursor: pointer;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
}

.home-stat-container h3{
    text-align: center;
    margin: 5px 0px 5px 0px;
}

.home-stat-container p{
    text-align: center;
    font-size: 22px;
    color: rgb(244, 129, 131);
    margin: 0px 0px 0px 0px;
}

.dashboard-output-container{
    width: auto;
}

.add-proof-container img{
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.add-proof-container{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dashboard-output-activity-meta-container{
    display: flex;
    align-items: center;
}

.dashboard-output-activity-meta-container p{
    margin-left: 5px;
}

.home-output-detail-graph-container{
    height: 200px;
    width: 100%;
}

.home-output-detail-container{
    width: 25%;
}

.home-output-detail-container h3{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 0px;
}

.home-outputs-container{
    margin-bottom: 20px;
}

.select-activity-container{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 30px;
    overflow-x: auto;
}

.select-activity-inner-container {
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin; /* For modern browsers */
  }

  .activity-select-item-container {
    min-width: 100px; /* Adjust based on your design */
    margin-right: 10px;
    flex-shrink: 0;
    text-align: center;
    border: 1px solid #ccc; /* Optional border for better visibility */
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

.activity-select-item-container:hover{
    background-color: #f4f4f4 !important;
}

.activity-select-item-container p{
    margin: 0px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.home-kpi-chart-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: 100%;
}

.home-kpi-graph-description-title-container{
    display: flex;
    align-items: center;
}

.home-kpi-graph-description-title-container img{
    height: 20px;
    margin-right: 10px;
}

.home-kpi-description-text{
    margin: 0px 0px 0px 33px;
    font-size: 12px;
}

.home-kpi-graph-description-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 75%;
}

.home-kpi-graph-description-item-container{
    display: flex;
    align-items: center;
}

.home-kpi-container{
    margin: 0px 30px 50px 30px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    width: 95%;
}

.home-kpi-description-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.home-kpi-description-container img{
    height: 20px;
    margin-right: 20px;
}

.home-kpi-description-container p{
    margin-left: 20px;
}

.home-kpi-chart-container-description{
    font-weight: bold;
    font-size: 12px;
}

#graph-display-settings-container{
    width: 98.5%;
    display: flex;
    justify-content: flex-end;
}

#graph-display-settings-container svg{
    margin-right: 3px;
    cursor: pointer;
}

#dashboard-output-detail-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 50px;
}

.dashboard-output-detail-item-container{
    width: 45%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#dashboard-graph-options-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}

#dashboard-graph-options-container svg{
    cursor: pointer;
    fill: #616161;
    margin-left: 5px;
}

#update-output-color-container input{
    width: 25px;
    box-shadow: none;
    padding: 0px
}

#onboarding-container{
    margin: 0px 30px 50px 30px;
    background-color: white;
    padding: 3px;
    border-radius: 15px;
    background-image: linear-gradient(white, white), linear-gradient(45deg, #f48183, #47acc3);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

#onboarding-inner-container{
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#home-decca-icon{
    font-size: 3.5rem;
}
