#add-event-output-item-container{
    display: flex;
    align-items: center;
}

#add-event-persona-item-container{
    display: flex;
    align-items: center;
}

#add-event-persona-item-container input{
    width: auto;
    margin-right: 10px;
}

.add-event-connect-persona-container{
    display: flex;
    flex-direction: column;
}

#research-results-import-button-container{
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

#research-results-import-button-container svg{
    margin-right: 10px;
}

#import-results-google-forms-data-table-container{
    margin-top: 20px;
}

#meetstandaard-effect-meta-container{
    display: flex;
    align-items: center;
}

#meetstandaard-effect-meta-container p{
    margin-right: 5px;
}